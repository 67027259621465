import React, { lazy } from 'react';
import { getCustomeBussinessRules } from '../../functions/customizeOrganization';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { wlsPremiumRollName } from '../../../customize/defaultValues';

const MeetingsList = lazy(() => import('../../../pages/meetings/meetings'));
const PaymentMain = lazy(() => import('../../../pages/payment/paymentMain'));


const StartMeeting = lazy(() => import('../../../pages/startMeeting/startMeeting'));

const DashboardMain = lazy(() => import('../../../pages/dasboard/dashboard'));
const RecordingsList = lazy(() => import('../../../pages/recordings/recordings'));
const Profile = lazy(() => import('../../../pages/profile/profile'));
const Subscription = lazy(() => import('../../../pages/subscription/subscription'));

const PaymentMethods = lazy(() => import('../../../pages/payment/paymentMethods'));
const Settings = lazy(() => import('../../../pages/settings/settings'));
const MyCohostsPage = lazy(() => import('../../../pages/cohosts/MyCohostsPage'));
const MyFavorites = lazy(() => import('../../../pages/myFavoritesMedia/MyFavoritesMedia'));


const customeRules = getCustomeBussinessRules();


export const routes = [
    {
        path: "payment-test",
        active: true,
        Component: <PaymentMain />
    },

    {
        path: "/:room/:meetingNumber",
        active: true,
        Component: <StartMeeting />
    },
    {
        path: "/my/:room",
        active: true,
        Component: <StartMeeting />
    },
    {
        path: "/r/:room/:meetingNumber",
        active: true,
        Component: <StartMeeting />
    }
]

export const dashboardRoutes = [
    {
        path: "dashboard",
        active: true,
        Component: <DashboardMain />,
        redirectOnHold: true
    },
    {
        path: "/recordings",
        active: true,
        Component: <RecordingsList />,
        redirectOnHold: true
    },
    {
        path: "/profile",
        active: true,
        Component: <Profile />,
        redirectOnHold: true
    },
    {
        path: "/account",
        active: true,
        Component: <Subscription />,
        redirectOnHold: true
    },
    {
        path: "/payment-methods",
        active: true,
        Component:
            <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
                <Elements> <PaymentMethods /> </Elements>
            </RecurlyProvider>
    },
    {
        path: "/settings",
        active: true,
        redirectOnHold: true,
        Component: <Settings />
    },
    {
        path: "/cohosts",
        active: true,
        redirectOnHold: true,
        grants: customeRules?.cohost?.type !== "none",
        Component: <MyCohostsPage />,
        roles: wlsPremiumRollName
    },
    {
        path: "all-meetings",
        active: true,
        redirectOnHold: true,
        Component: <MeetingsList />
    },
    {
        path: "my-media",
        active: true,
        redirectOnHold: true,
        Component: <MyFavorites />
    },
]


export const noNavbarRoutes = ["/on-hold"]