import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./common.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import { getAuth0Org } from "./commons/functions/url";

const root = ReactDOM.createRoot(document.getElementById("root"));

export const history = createBrowserHistory();

const onRedirectCallback = (appState, params) => {
  if (process.env.NODE_ENV === "production") {
    let orgtoRedirect = params?.["https://wherebyfront.vercel.app/orgname"];
    if(orgtoRedirect === "wave4"){
      orgtoRedirect = "meet";
    }
    let url = orgtoRedirect
      ? `https://${orgtoRedirect}.mypxch.com/dashboard`
      : undefined;
    window.location.replace(
      url || appState?.returnTo || window.location.pathname
    );
  } else {
    let urlLocal = `localhost:3000/dashboard?org=${params?.["https://wherebyfront.vercel.app/orgname"]}`;
    window.location.replace(urlLocal);
  }
};

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri:
        process.env.REACT_APP_AUTH0_BASE_URL || window.location.origin,
      audience: ["https://sellanywhere.us.auth0.com/api/v2/"],
      scope: process.env.REACT_APP_AUTH0_SCOPE,
      organization: getAuth0Org()?.org_code,
      organization_name: getAuth0Org()?.org_name,
    }}
    //cookieDomain={'.mypxch.com'}
    onRedirectCallback={onRedirectCallback}
  >
    <ToastContainer
      position={"top-right"}
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={false}
      draggable={true}
      progress={undefined}
      theme={"light"}
      limit={2}
    />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
