export const defaultImg = 'https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg';
export const defaultCurrency = {
    title: "United States | English",
    currency: "usd",
    country: "US",
    language: "en",
};

export const defaultLoginAsUser = {
  loginMessage: "If you already have an account, login to have a fast and easy checkout.",
  loginLabel: "Company ID:"
}
export const defaultWLSRoles = ["wl_meeting_free","wl_shop_free","Customer","wlshop"];
export const wlsRollName = ["wl_meeting_free","wl_shop_free","Customer","wlshop"];
export const wlsPremiumRollName = ["wl_shop_free","wlshop", "wl_meeting_free"];
export const wlsFreeRoles = ["wl_meeting_free", "wl_shop_free"];
export const wlsFreeSandboxRoles = ["wl_meeting_free", "wl_shop_free"];

export const wlsDisabledRoles = ["wl_shop_on_hold"];


export const ulrsTutorialVideo = {
  streamingSetUp: "", 
  uploadPresentation: "",
}